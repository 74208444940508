.cursor {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  contain: layout style size;
  will-change: transform;
  pointer-events: none;

  .cursor-inner {
    position: absolute;
    top: -24px;
    left: -24px;
    display: block;
    width: 48px;
    height: 48px;
    transform: scale(0.2);
    background: black;
    border-radius: 50%;
  }

  @include media("<=tablet") {
    display: none;
  }
}
