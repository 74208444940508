.managed-features {
  background-color: #f5f9fc;
  padding: 100px 0 100px;
  position: relative;

  .features-inner {
    width: 100%;

    .features-content {
      margin: 0 auto;

      .features-content-managed-inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include media("<=desktop") {
          flex-direction: column-reverse;
        }

        .image {
          width: 40%;
          position: absolute;
          top: -50px;
          right: 0;
          z-index: 10;

          .image-inner {
            position: relative;

            .img1 {
              max-width: 430px;
            }

            .img2 {
              position: absolute;
              left: 30px;
              top: 60px;
              max-width: 350px;
            }
          }

          @include media("<=desktop") {
            position: initial;
            display: none;
            justify-content: center;

            width: 100%;
          }
        }

        .features-items {
          max-width: 700px;

          h3 {
            font-family: $font;
            font-size: 2.25rem;
            color: $blueFeatures;
            font-weight: 500;
            margin: 0px 0px 30px 0px;
          }

          p {
            font-family: $fontP;
            font-size: 1rem;
            line-height: 32px;
            color: $greyP;
            margin: 0;
          }

          @include media("<=desktop") {
            width: 100%;
            text-align: center;
          }

          .btn-row-features {
            @include media("<=desktop") {
              margin: 30px auto;
            }
          }
        }
      }
    }
  }
}
