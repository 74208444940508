.btn-row {
  background-color: white;

  padding: 20px 60px;
  border-radius: 100px;

  cursor: pointer;
  transition: box-shadow 0.3s ease-in;
  &:hover {
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.16);
  }
}

.btn-row-features {
  text-align: center;
  margin-top: 30px;
  padding: 25px 15px;
  color: $white;
  position: relative;
  border-radius: 100px;
  width: 170px;
  cursor: pointer;

  background: rgb(116, 100, 253);
  background: -moz-linear-gradient(
    4deg,
    rgba(116, 100, 253, 1) 0%,
    rgba(88, 81, 224, 1) 30%,
    rgba(88, 81, 224, 1) 100%
  );
  background: -webkit-linear-gradient(
    4deg,
    rgba(116, 100, 253, 1) 0%,
    rgba(88, 81, 224, 1) 30%,
    rgba(88, 81, 224, 1) 100%
  );
  background: linear-gradient(
    4deg,
    rgba(116, 100, 253, 1) 0%,
    rgba(88, 81, 224, 1) 30%,
    rgba(88, 81, 224, 1) 100%
  );
  transition: box-shadow 0.3s ease-in;
  &:hover {
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.16);
  }

  a {
    color: $white;
    text-decoration: none;
    font-family: $font;
    font-size: 0.875rem;
  }

  @include media("<=tablet") {
    margin: 30px auto;
  }
}
