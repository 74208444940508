@import "../css/normalize.scss";
@import "../css/variables.scss";
@import "../css/breakpoints.scss";
@import "./components/cursors.scss";
@import "../css/components/header.scss";
@import "../css/components/banner.scss";
@import "../css/components/button.scss";
@import "../css/components/features.scss";
@import "../css/components/managed-features.scss";
@import "../css/components/users.scss";
@import "../css/components/overlay.scss";
@import "../css/components/newsletter.scss";
@import "../css/components/footer.scss";

* {
  box-sizing: border-box;
}
::-moz-selection {
  text-shadow: 0.15em 0.15em 0em rgba(116, 100, 253, 0.5);
}

::selection {
  text-shadow: 0.15em 0.15em 0em rgba(116, 100, 253, 0.5);
}

body,
html {
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  font-size: 100%;
  font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;

  @include media("<=1560px") {
    width: 1280px;
    min-width: 1280px;
  }
  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }
  @include media("<=1095px") {
    width: 100%;
    min-width: 100%;
  }
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    @include media("<=phone") {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;

  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;
  }
}
