// //media query
// $mobile: "480px";
// $tablet: "768px";
// $laptop: "960px";
// $desktop: "1200px";
// $widescreen: "1440px";

//Container Padding

// color
$blue: #4c83ff;
$grey: #c6c5c4;
$greyDark: #5f5c55;
$orange: #daa932;
$white: #ffffff;
$blueTitle: #324865;
$blueFeatures: #1a264a;
$greyP: #585e68;

$font: "Poppins", sans-serif;
$fontP: "Open Sans", sans-serif;

$poppinsLight: 300;
$poppinsRegular: 400;
$poppinsMedium: 500;
$poppinsSemiBold: 600;
$poppinsBold: 600;
