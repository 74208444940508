header {
  position: absolute;
  width: 100%;
  height: 100px;
  z-index: 10;

  .header-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100px;
    margin: 0 auto;

    .logo {
      display: flex;
      align-items: center;
      z-index: 220;
      .logo-span {
        margin-left: 10px;

        span {
          font-family: $font;
          color: white;
          font-size: 1.8rem;
          font-weight: 700;
          z-index: 10;
        }
      }
    }
  }

  .navigation {
    ul {
      display: flex;
      align-items: center;
      padding: 0;
    }

    li {
      position: relative;
      list-style: none;
      padding: 0 25px;
      cursor: pointer;

      a {
        position: relative;
        display: block;
        color: $white;
        font-family: $font;
        font-size: 0.875rem;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 100%;
          height: 3px;
          background: white;
          transform: scale(0, 1);
          transform-origin: 0% 100%;
          transition: transform 0.35s;
        }
        &:hover::after {
          transform: scale(1, 1);
        }
      }
    }

    .btn-nav {
      background-color: white;

      border-radius: 100px;
      padding: 20px 30px;

      a {
        color: $blue;
        text-decoration: none;
      }
    }

    @include media("<=1200px") {
      display: none;
    }
  }
}
