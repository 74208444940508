.newsletter {
  width: 100%;
  padding: 100px 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  .content-newsletter {
    position: relative;

    svg {
      position: absolute;
    }
    .header-newsletter {
      width: 100%;
      border-bottom: 1px solid #cbe3fa;

      h3 {
        font-family: $font;
        font-size: 1.5em;
        font-weight: 400;
        color: #cedaf8;
        text-align: center;
        margin-bottom: 30px;
      }
      ul {
        list-style: none;
        padding: 0;
        display: flex;

        @include media("<=tablet") {
          flex-wrap: wrap;
          justify-content: center;
        }
        li {
          padding: 0px 10px;
          width: 16.666%;
          @include media("<=tablet") {
            width: 33.333%;
          }
          img {
            max-width: 100%;
          }
        }
      }
    }
    .content-inner-newsletter {
      padding-top: 100px;
      padding-bottom: 100px;
      h2 {
        font-family: $font;
        font-size: 2.25rem;
        color: $blueFeatures;
        font-weight: 500;
        text-align: center;
        margin-bottom: 100px;
      }
      .form-inner {
        text-align: center;
        #email {
          padding: 30px 50px;
          border-radius: 100px;
          border: 1px solid $blue;
          width: 500px;
          font-family: $font;
          font-size: 1.5em;
          font-weight: 400;
          color: $blue;
          box-sizing: border-box;
          margin-right: 30px;
          &::placeholder {
            font-family: $font;
            font-weight: 400;
            color: $blue;
          }
          @include media("<=tablet") {
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            margin: 0px;
            text-align: center;
            &::placeholder {
              text-align: center;
            }
          }
        }
        #btn-submit {
          padding: 20px 0px;
          border-radius: 100px;
          border: 1px solid $blue;
          width: 200px;
          font-family: $font;
          font-size: 1.5em;
          font-weight: 400;
          color: $white;
          background: rgb(116, 100, 253);
          background: linear-gradient(
            0deg,
            rgba(116, 100, 253, 1) 0%,
            rgba(88, 81, 234, 1) 50%,
            rgba(88, 81, 234, 1) 100%
          );
          box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.16);
          border: none;
          cursor: pointer;
          @include media("<=tablet") {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
