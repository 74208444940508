.banner {
  width: 100%;
  position: relative;
  z-index: 0;

  background: rgb(116, 100, 253);
  background: linear-gradient(
    0deg,
    rgba(116, 100, 253, 1) 0%,
    rgba(88, 81, 234, 1) 50%,
    rgba(88, 81, 234, 1) 100%
  );

  .background-image {
    position: absolute;
    width: 100%;
    bottom: 0px;
    overflow: hidden;
    z-index: 1;
  }

  .banner-inner {
    width: 100%;
    height: 100vh;

    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .content-inner {
      h1 {
        text-align: center;
        color: $white;
        font-family: $font;
        font-weight: 600;
        line-height: 4rem;
        font-size: 3rem;
        filter: blur(0px);

        .line {
          overflow: hidden;
        }
      }

      .btn-content {
        width: 450px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        will-change: transform;

        .btn-row {
          a {
            color: $blue;
            font-size: 1rem;
            font-family: $font;
            text-decoration: none;
          }
        }

        .btn-row-second {
          a {
            color: $white;
            font-size: 1rem;
            font-family: $font;
            position: relative;
            text-decoration: none;
            position: relative;

            &:after {
              content: "";
              position: absolute;
              left: -25%;
              bottom: -10px;
              width: 225px;
              height: 3px;
              background: white;
              transform: scale(0, 1);
              transform-origin: 0% 100%;
              transition: transform 0.35s;
            }
            &:hover::after {
              transform: scale(1, 1);
            }
          }
        }

        @include media("<=phone") {
          width: 100%;
          height: 150px;
          flex-direction: column;
        }
        // @media (max-width:$mobile) {
        //   width: 100%;
        //   height: 150px;
        //   flex-direction: column;
        // }
      }
    }
    @include media("<=phone") {
      height: 100vh;
    }
    // @media (max-width:$mobile) {
    //   height: 100vh;
    // }
  }
}
