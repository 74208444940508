@import "../components/button.scss";

.features {
  padding: 100px 0;
  position: relative;

  .features-obj {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include media("<=tablet") {
      display: none;
    }
    img {
      position: absolute;
    }

    .img1 {
      left: 10%;
    }

    .img2 {
      right: 100px;
      top: 15%;
    }
    .img3 {
      left: 100px;
      top: 35%;
    }
    .circle1 {
      right: -350px;
      top: 20%;
    }
    .circle2 {
      left: -400px;
      top: 55%;
    }
    .img1-1 {
      left: 25%;
      top: 60%;
    }
    .img2-1 {
      right: 0;
      top: 70%;
    }
    .img3-1 {
      right: 30%;
      top: 90%;
    }
  }

  .features-inner {
    width: 100%;

    .features-title {
      max-width: 800px;
      margin: 0 auto;

      h2 {
        text-align: center;
        font-family: $font;
        font-size: 2.3rem;
        color: $blueTitle;
        font-weight: 700;
      }

      p {
        margin: 30px 0 100px;
        text-align: center;
        font-family: $fontP;
        font-size: 1rem;
        line-height: 2rem;
        color: $greyP;
        font-weight: 300;
      }
    }

    .reverse {
      flex-direction: row-reverse;
    }

    .features-content {
      position: relative;
      .features-line {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        width: 57%;
        margin: 0 auto;
        z-index: -10;

        @include media("<=desktop") {
          display: none;
          width: 70%;
        }
        @include media("<=tablet") {
          display: none;
        }
      }

      .features-content-inner {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 200px;

        @include media("<=tablet") {
          &:last-child {
            margin-bottom: 0;
          }
        }
        .reverse {
          flex-direction: row-reverse;
        }

        .image {
          width: 40%;

          img {
            max-width: 100%;
            display: flex;
            justify-content: center;
            margin: 0 auto;
          }
          @include media("<=tablet") {
            width: 100%;
          }
        }

        .features-items {
          width: 40%;

          h3 {
            font-family: $font;
            font-size: 2.25rem;
            color: $blueFeatures;
            font-weight: 500;
            margin: 0px 0px 30px 0px;
          }

          p {
            font-family: $fontP;
            font-size: 1rem;
            line-height: 32px;
            color: $greyP;
            margin: 0;
          }

          @include media("<=tablet") {
            width: 100%;
            text-align: center;
            margin-top: 50px;
          }
        }
      }
    }
  }
}
