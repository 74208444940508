.burger-menu {
  width: 25px;
  height: 23px;

  display: none;
  cursor: pointer;
  position: relative;
  z-index: 220;
  @include media("<=1200px") {
    display: flex;
  }
}

.burger-menu span {
  width: 25px;
  height: 2px;

  background-color: white;
  border-radius: 100px;

  opacity: 1;
}

.burger-menu span:nth-child(1) {
  position: absolute;
  transition: 0.2s cubic-bezier(0.78, -0.41, 0.3, 1.37);
  top: 0;
}

.burger-menu span:nth-child(2) {
  position: absolute;
  top: 8px;
  transition: 0.1s cubic-bezier(0.78, -0.41, 0.3, 1.37);
}

.burger-menu span:nth-child(3) {
  position: absolute;
  top: 16px;
  transition: 0.2s cubic-bezier(0.78, -0.41, 0.3, 1.37);
}

.burger-menu.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 8px;
}

.burger-menu.open span:nth-child(2) {
  opacity: 0;
}

.burger-menu.open span:nth-child(3) {
  top: 8px;
  transform: rotate(-45deg);
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: black;
  visibility: hidden;
  z-index: 200;
  transition: all 0.5s ease-in-out;
  transform: translateX(-100%);
  -webkit-overflow-scrolling: touch;

  .overlay-inner {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        padding: 15px 0;
        a {
          position: relative;
          text-decoration: none;
          color: white;
          font-size: 25px;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            height: 3px;
            background: white;
            transform: scale(0, 1);
            transform-origin: 0% 100%;
            transition: transform 0.35s;
          }
          &:hover::after {
            transform: scale(1, 1);
          }
        }
      }
      .btn-nav {
        background-color: white;
        margin-top: 20px;
        border-radius: 100px;
        padding: 25px 100px;

        a {
          color: $blue;
          text-decoration: none;
        }
      }
    }
  }
}

.is-visible {
  visibility: visible;
  transform: translateX(0%);
  transition: all 0.5s ease-in-out;
  overflow-y: hidden;
}
