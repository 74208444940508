.users-reviews {
  background: rgb(116, 100, 253);
  background: -moz-linear-gradient(
    4deg,
    rgba(116, 100, 253, 1) 0%,
    rgba(88, 81, 224, 1) 30%,
    rgba(88, 81, 224, 1) 100%
  );
  background: -webkit-linear-gradient(
    4deg,
    rgba(116, 100, 253, 1) 0%,
    rgba(88, 81, 224, 1) 30%,
    rgba(88, 81, 224, 1) 100%
  );
  background: linear-gradient(
    4deg,
    rgba(116, 100, 253, 1) 0%,
    rgba(88, 81, 224, 1) 30%,
    rgba(88, 81, 224, 1) 100%
  );

  width: 100%;
  padding: 300px 0px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow-x: hidden;

  @include media("<=tablet") {
    padding: 100px 0px;
  }
  .reviews-inner {
    width: 100%;

    .reviews-header {
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      .reviews-title {
        h3 {
          font-family: $font;
          font-size: 2.3rem;
          color: $white;
          font-weight: 500;
          margin: 0;
        }
      }
      .swiper-button {
        width: 120px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-swiper {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          transition: background-color 0.3s ease-in;
          &:hover {
            background-color: rgba(250, 250, 250, 0.5);
          }
          &.swiper-button-disabled {
            &:hover {
              background-color: rgba(250, 250, 250, 0.1);
            }
            svg {
              opacity: 0.3;
            }
          }

          svg {
            stroke: $white;
            display: inline-block;
            height: 15px;
            transition: opacity 0.3s ease-out;
          }
          &:first-child svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .content-reviews {
    position: relative;
    .swiper-container {
      overflow: visible;
    }
    .swiper-slide {
      width: calc(50% - 15px);
      padding-top: 50px;
      padding-bottom: 50px;
      padding-right: 100px;
      padding-left: 100px;
      margin-right: 30px;
      background-color: white;
      border-radius: 15px;
      box-sizing: border-box;
      @include media("<=desktop") {
        width: 66.666%;
        padding-right: 50px;
        padding-left: 50px;
      }
      @include media("<=tablet") {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: 10px;
      }
      .user-text {
        text-align: center;
        margin: 10px auto;
        box-sizing: border-box;
        @include media("<=tablet") {
          padding: 0 12px;
        }
        p {
          font-family: $font;
          font-size: 1.1rem;
          color: #1a264a;
          font-weight: $poppinsLight;
          line-height: 30px;
          margin: 0;
        }
      }

      .user-img {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        background-color: rgba(102, 80, 213, 0.15);
        margin: 30px auto;
        padding: 3px;
        img {
          max-width: 100%;
        }
      }

      .user-name {
        margin-top: 20px;
        text-align: center;
        span {
          font-family: $font;
          font-size: 1.2rem;
          color: #1a264a;
          font-weight: $poppinsSemiBold;
        }
      }
    }
  }

  // .swiper-pagination {
  //   position: initial;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin-top: 100px;

  //   @media (max-width: $tablet) {
  //     margin-top: 50px;

  // }
  //   .swiper-pagination-bullet {
  //     transform: scale(0.7);
  //     border-radius: 100px;
  //     background-color: rgba(15, 15, 43, 0.5);
  //     opacity: 1;
  //   }
  //   .swiper-pagination-bullet-active {
  //     transform: scale(1);
  //     width: 10px;
  //     height: 10px;
  //     background-color: rgba(15, 15, 43, 1);
  //   }
  // }
}
