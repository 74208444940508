#footer {
  position: relative;
  width: 100%;
  padding-top: 200px;
  background: rgb(116, 100, 253);
  background: linear-gradient(
    0deg,
    rgba(116, 100, 253, 1) 0%,
    rgba(88, 81, 234, 1) 50%,
    rgba(88, 81, 234, 1) 100%
  );
  .background-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    svg {
      @include media("<=desktop") {
        width: 200%;
      }
    }
  }

  .footer-content {
    z-index: 10;
    .content-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      z-index: 10;
      border-bottom: 1px solid $white;
      padding-bottom: 50px;

      @include media("<=tablet") {
        flex-direction: column;
      }
      .footer-content-header-left {
        width: 25%;
        color: white;
        @include media("<=tablet") {
          width: 100%;
          margin-bottom: 50px;
        }
        .logo-footer {
          display: flex;
          align-items: center;
          z-index: 10;
          .logo-span {
            margin-left: 10px;
            span {
              font-family: $font;
              font-size: 1.8rem;
              font-weight: 700;
              z-index: 10;
            }
          }
        }
        p {
          font-family: $font;
          font-weight: $poppinsLight;
          line-height: 30px;
          font-size: 0.9rem;
        }
        ul {
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          li {
            list-style: none;
            padding: 10px 0px;
            width: 25%;

            a {
              text-decoration: none;
              color: $white;
            }
          }
        }
      }
      .footer-content-header-right {
        width: 60%;
        display: flex;
        @include media("<=tablet") {
          display: none;
        }
        .footer-content-inner-section {
          width: 33.333%;
          h2 {
            color: $white;
            margin: 0;
            font-family: $font;
            font-weight: $poppinsSemiBold;
            font-size: 1.2rem;
          }
          ul {
            padding: 0;
            li {
              list-style: none;
              padding: 10px 0px;
              a {
                text-decoration: none;
                color: $white;
                font-family: $font;
                font-weight: $poppinsLight;
                font-size: 0.9rem;
                position: relative;

                &::after {
                  content: "";
                  position: absolute;
                  left: 0;
                  bottom: -10px;
                  width: 100%;
                  height: 3px;
                  background: white;
                  transform: scale(0, 1);
                  transform-origin: 0% 100%;
                  transition: transform 0.35s;
                }
                &:hover::after {
                  transform: scale(1, 1);
                }
              }
            }
          }
        }
      }
    }

    .content-footer {
      width: 100%;
      padding: 80px 0px;
      text-align: center;
      p {
        font-family: $font;
        font-weight: $poppinsLight;
        color: $white;
        font-size: 1rem;
      }
    }
  }
}
